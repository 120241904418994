(function ($) {

  $(document).ready(function () {

    // ********** jQuery plugins ************ 

    $('body').scrollToAnchors();

    $('body').scrollToAnchors();

    // $('#bottom').bottomize();

    $('.glyphicons').glyphClone();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('.bob-frame-show').bobframe();

    // PAGE1 PATIENTS FORMS
    if ($('body.page_3').length > 0) {
      $('body').voucherAxel();
    }

  });

}(jQuery));