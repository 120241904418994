// selectors
export const FRAME_SELECTOR = ".bob-frame";
export const FRAME_FORM_SELECTOR = ".bob-frame form";
export const MSG_SELECTOR = ".bob-msg";
export const MSG_ACTIVE_SELECTOR = ".bob-msg.active";

// storage
export const STORAGE_FRAME_STATE = "bob_frame_state";
export const STORAGE_MSG_STATE = "bob_msg_state";
export const STORAGE_FORM_DATA = "bob_form_data";

// events frame
export const EVENT_FRAME_OPEN = "bobFrameContentOpen";
export const EVENT_FRAME_CONTENT_CHANGED = "bobFrameContentChanged";
export const EVENT_FRAME_CONTENT_NEXT = "bobFrameContentNext";
export const EVENT_FRAME_HIDE = "bobFrameHide";
export const EVENT_FRAME_AFTER_HIDE = "bobFrameAfterHide";

// events form
export const EVENT_FORM_SUBMIT = "bobFormSubmit";
export const EVENT_FORM_COMMIT = "bobFormCommit";
export const EVENT_FORM_SUCCESS = "bobFormSuccess";
export const EVENT_FORM_WARNING = "bobFormWarning";
export const EVENT_FORM_ERROR = "bobFormError";
export const EVENT_FORM_ERROR_CHECKSUM = "bobFormErrorChecksum";
export const EVENT_FORM_ERROR_DECLINED = "bobFormErrorDeclined";
export const EVENT_FORM_ERROR_EXPIRED = "bobFormErrorExpired";
export const EVENT_FORM_ERROR_NOTFOUND = "bobFormErrorNotfound";
export const EVENT_FORM_ERROR_INSURER = "bobFormErrorInsurer";
