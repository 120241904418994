import * as Globals from './globals_dak.js'

(function ($) {

    /**
     * diga Access plugin
     * prepares/validates the data coming from the checksum form
     * Connects to the diga api on bobserver
     * Handles the response of the diga api
     * The voucher with the accesscode as a parameter is attached to a link in the survey form
     * @returns {$this}
     */

    $.fn.zenBgm = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }

        let settings = $.extend({}, options);
        let pre = settings.prefix;
        let g = Globals;
        let event_namespace = '.zenBgm';

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        /**
         * submitForm
         * 
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            _.submitBtnDisabled(pre, true);

            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT);


            let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);

            if (!validate(formData)) {
                // enable button again
                _.submitBtnDisabled(pre, false);

                // event listened on by bob-msg
                _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_WARNING);

                return;
            }

            let data = _.getFormData(g.FRAME_FORM_SELECTOR).data;
            let labels = _.getFormData(g.FRAME_FORM_SELECTOR).label;

            data.description = `${labels.firm}: ${data.firm} \n send from ${window.location.href}`;

            $('document').spinner('show');

            $.ajax({
                url: "/zen",
                type: "POST",
                data: data,
                cache: false,
                success: function () {

                    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUCCESS);
                    // enable button again
                    $('#submit', '.bob-frame').prop('disabled', false);
                    $('document').spinner('hide');
                },

                error: function () {

                    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);
                    // enable button again
                    $('#submit', '.bob-frame').prop('disabled', false);
                    $('document').spinner('hide');
                }
            });
        };

        /**
         * Validate form data and (maybe) modify formData object
         * @param formData
         * @returns {boolean}
         */
        let validate = function (formData) {

            let d = formData.values;
            let validItem1 = false;
            let validItem2 = false;
            let validItem3 = false;

            validItem1 = _.checkLength(d.name.element, d.name.value, 1, 100);

            if (d.email.value.trim() === "" || _.validateEmail(d.email.value.trim()) === false) {
                d.email.element.addClass('is-invalid');
            } else {
                d.email.element.removeClass('is-invalid');
                validItem2 = true;
            }

            validItem3 = _.checkLength(d.firm.element, d.firm.value, 1, 100);

            return validItem1 && validItem2 && validItem3;
        };

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible 
        $(g.FRAME_FORM_SELECTOR).off(event_namespace);


        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(g.FRAME_FORM_SELECTOR).on('submit' + event_namespace, function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));