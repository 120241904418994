// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import '../styles/main.scss';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common.js
// the files are copied into the "fromLibs" folder.
import '../scripts/fromLibs/jquery.scrollTo.min.js';

//Bootstrap
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';

// Bob scripts form Libs
import '../scripts/fromLibs/spinner.js';
import '../scripts/fromLibs/glyphicons.js';
import '../scripts/fromLibs/scrollTop_arrow.js';
import '../scripts/fromLibs/scrollTo_anchors.js';
import '../scripts/fromLibs/fadePage.js';
import '../scripts/fromLibs/bobframe6.js'
import '../scripts/fromLibs/bobmsg4.js'
import '../scripts/fromLibs/form_voucher_axel.js'
import '../scripts/fromLibs/form_voucher_dak.js'
import '../scripts/fromLibs/form_zen_bgm.js'
import '../scripts/fromLibs/globals_dak.js'
import '../scripts/fromLibs/tracking_class_creator.js'
import '../scripts/fromLibs/util2.js'

// Project specific scripts
import '../scripts/main.js';